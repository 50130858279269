import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AppLayout from 'layouts/app-layout';
import { Modal, Tabs } from 'components/molecules';
import ReportModal from './report-modal';
import {
  TabContent,
  SummaryContainer,
  TableContainer,
  Matches,
  ProfileContainer,
  ButtonWrapper,
  WhiteOutlineButton,
  StatusChip,
  Description,
  StatusInfo,
  ErrorText,
  NoMatchText,
  Section,
  ButtonMenu,
  ButtonMenuItem,
} from './ThirdPartyScreening.styles';
import SectionSummary from 'components/molecules/section-summary';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import ProfileDetails from 'components/molecules/profile-details';
import OrderDetails from 'components/molecules/order-details';
import { Button, Icon, Link, Loading } from 'components/atoms';
import { ScreeningContext, ScreeningProvider } from 'context/screening';
import { Redirect, useHistory, useParams } from 'react-router';
import { statusMapper } from 'services/status-mapper';
import { mapNotesData, mapSettingsData, mapSummaryData } from './DataMap';
import ScreeningTable from 'components/molecules/screening-table';
import EditMonitoringModal from 'pages/third-party-screening/edit-monitoring-modal';
import { GatewayPermissions } from 'auth/constants/permissions';
import { abbreviations } from 'components/atoms/abbreviation/config';
import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';
import DueDiligenceDocuments from './due-diligence-documents/DueDiligenceDocuments';
import { useTheme } from 'styled-components';
import DueDiligenceDeleteModal from 'pages/third-party-profile/sections/due-diligence/due-diligence-delete-modal';
import { ReactComponent as StatusIcon } from './../../assets/svg/Icons/Status.svg';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import DualButton from 'components/atoms/dual-button/DualButton';
import { downloadBlob } from 'helpers/downloadHelper';
import {
  ReportProceedingModalContainer,
  ModalParagraph,
  ButtonContainer,
} from './report-modal/ReportModal.styles';
import { getFileExtension } from 'helpers/documentHelper';
import ScreeningMessage from 'components/molecules/screening-message';
import { useCountries } from 'context/countries';
import { FormProvider, useForm } from 'react-hook-form';
import { ScreeningTableState } from 'components/molecules/screening-table/types';

type ThirdPartyScreeningParams = {
  id: string;
};

function ThirdPartyScreening() {
  return (
    <ScreeningProvider>
      <ScreeningPage />
    </ScreeningProvider>
  );
}

export function ScreeningPage() {
  const { accessToken, hasPermission, userName } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi, msalSubscriptionKey } = useConfig();
  const {
    screeningState: { countryFilter, reasonListedFilter },
  } = useContext(ScreeningContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeProfile, setActiveProfile] = useState(null);
  const [editMonitoringOpen, setEditMonitoringOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [isButtonMenuOpen, setIsButtonMenuOpen] = useState(false);
  const buttonMenuRef = useRef<HTMLDivElement>(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const theme = useTheme();

  const [notes, setNotes] = useState({
    resolutionNotes: '',
    WatchListNotes: '',
    enforcementsNotes: '',
    politicalNotes: '',
    adverseNotes: '',
    resolutionNotesRiskScore: null,
    WatchListNotesRiskScore: null,
    enforcementsNotesRiskScore: null,
    politicalNotesRiskScore: null,
    adverseNotesRiskScore: null,
  });
  const { id } = useParams<ThirdPartyScreeningParams>();
  const history = useHistory();
  const [selected, setSelected] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const formMethods = useForm<ScreeningTableState>();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const { data: typesData, trigger: getDueDiligenceTypes } = useFetch(
    `${vantageWebApi}/onlinescreening/duediligencetypes`,
    {
      method: 'GET',
      lazy: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const { data: addOnsData } = useFetch(
    `${vantageWebApi}/client/managedDueDiligence/optionalAddOns`,
    {
      headers,
      method: 'GET',
    }
  );

  const {
    data: statusData,
    loading: statusLoading,
    trigger: triggerStatus,
  } = useFetch(`${vantageWebApi}/onlinescreening/${id}/status`, {
    method: 'GET',
    headers,
  });

  const { trigger: triggerEditScreening, data: editScreeningData } = useFetch(
    `${vantageWebApi}/onlinescreening/${id}/edit`,
    {
      method: 'PUT',
      headers,
      lazy: true,
    }
  );

  const { trigger: triggerUnlockScreening, data: unlockScreeningData } =
    useFetch(`${vantageWebApi}/onlinescreening/${id}/unlock`, {
      method: 'PUT',
      headers,
      lazy: true,
    });

  const {
    data: matchData,
    trigger: triggerMatches,
    error,
  } = useFetch(`${vantageWebApi}/onlinescreening/${id}/matches`, {
    method: 'POST',
    headers,
    lazy: true,
    body: JSON.stringify({
      page: pageNumber,
      pageSize: 50,
      filter: {
        countries: countryFilter.selectedCountries,
        reasonListed: reasonListedFilter.selectedReasons,
      },
    }),
  });

  const {
    trigger: triggerResolveScreening,
    data: resolveData,
    loading: resolveLoading,
  } = useFetch(`${vantageWebApi}/onlinescreening/${id}/resolve`, {
    method: 'PUT',
    lazy: true,
    headers,
    body: JSON.stringify({
      matchResolutions: [],
      resolvedBySource: statusData?.screenedBySourceChannelId,
      resolvedByAnalyst: userName,
      ...notes,
    }),
  });

  const {
    data: finalizeResponse,
    loading: finalizeLoading,
    trigger: finalizeLazy,
    error: finalizeError,
  } = useFetch(
    `${vantageWebApi}/onlinescreening/${id}/finalise`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    'text'
  );

  const hasViewTaskList: boolean = hasPermission(
    GatewayPermissions.ViewTaskList
  );

  const approvalStatusModalEnabled =
    statusData?.client?.isUpdateApprovalStatusModalEnabled ?? false;

  const onFinalizeClick = () => {
    history.push(`/third-party/profile/${statusData?.thirdParty?.id}`);
  };

  const onApproveClick = () => {
    history.push(`/third-party/edit-approval/${statusData?.thirdParty?.id}`);
  };

  const onSubmit = (data: ScreeningTableState) => {
    const matches = data?.matches ?? {};
    var matchArray = Object.values(matches);
    triggerResolveScreening({
      body: JSON.stringify({
        matchResolutions: matchArray,
        resolvedBySource: statusData?.screenedBySourceChannelId,
        resolvedByAnalyst: userName,
        ...notes,
      }),
    });
  };

  useEffect(
    () => {
      if (finalizeResponse && !finalizeError) {
        if (
          hasPermission(GatewayPermissions.ApproveRejectThirdParty) &&
          approvalStatusModalEnabled
        ) {
          setIsConfirmationModalOpen(false);
          setIsApprovalModalOpen(true);
        } else {
          onFinalizeClick();
        }
      }
    },
    // eslint-disable-next-line
    [
      finalizeResponse,
      finalizeError,
      setIsConfirmationModalOpen,
      hasPermission,
      setIsApprovalModalOpen,
      approvalStatusModalEnabled,
    ]
  );

  useEffect(() => {
    if (statusData && statusData.dueDiligenceType?.includes('Screening')) {
      getDueDiligenceTypes();
    }
  }, [statusData, getDueDiligenceTypes]);

  useEffect(() => {
    const reloadData = () => {
      triggerStatus();
      triggerMatches();
      setSelected(0);
    };
    if (editScreeningData || unlockScreeningData) reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editScreeningData, unlockScreeningData, triggerMatches, triggerStatus]);

  const showProfile = (id: number) => {
    setActiveProfile(matchData.results.find((result) => result.id === id));
  };

  const selfServiceScreening =
    statusData?.dueDiligenceType ===
    GatewaySubscriptionTypeNames.SelfServiceScreening;
  const managedServiceScreening =
    statusData?.dueDiligenceType ===
    GatewaySubscriptionTypeNames.ManagedServiceScreening;

  const hasEditMonitoringPermission = useMemo(() => {
    if (selfServiceScreening) {
      return hasPermission(GatewayPermissions.EditSSMonitoring);
    } else if (managedServiceScreening) {
      return hasPermission(GatewayPermissions.EditMSMonitoring);
    }
    return false;
  }, [selfServiceScreening, managedServiceScreening, hasPermission]);

  const isMDD = statusData?.dueDiligenceType.includes('DueDiligence');
  const isChildReport = statusData?.parentReportId;
  const reportFileName = statusData?.reportFileName;
  const countriesData = useCountries();
  const canEditMonitoring = (typesData, dueDiligenceType) => {
    if (!typesData || !Array.isArray(typesData)) {
      return false;
    }

    const dueDiligenceTypeSelected =
      dueDiligenceType === GatewaySubscriptionTypeNames.ManagedServiceScreening
        ? 'Managed Service'
        : 'Self Service';

    if (
      dueDiligenceType ===
        GatewaySubscriptionTypeNames.ManagedServiceScreening ||
      dueDiligenceType === GatewaySubscriptionTypeNames.SelfServiceScreening
    ) {
      return typesData
        .flatMap((screening) => screening.dueDiligenceTypes || [])
        .some(
          (dueDiligence) =>
            dueDiligence.name === dueDiligenceTypeSelected &&
            dueDiligence.isMonitoringEnabled
        );
    }
    return false;
  };

  const summary = mapSummaryData(statusData, countriesData);
  const settings = mapSettingsData(
    statusData,
    addOnsData,
    typesData,
    terminologyConfig?.internalIdSingularNaming
  );
  const notesOptions = mapNotesData(statusData);
  const screeningStatus = statusMapper(
    statusData?.screeningReportStatusId,
    statusData?.state,
    statusData?.dueDiligenceType,
    statusData?.reportStatus
  );
  const isErrored = screeningStatus?.includes('Errored');
  const isComplete = screeningStatus?.includes('Complete');
  const statusTagColor = isComplete
    ? 'poisonIvy'
    : isErrored
    ? 'redRampage'
    : ('fluorescentOrange' as const);

  if (resolveData) {
    history.push(`/report/${id}`);
  }
  const useReload = useCallback(() => {
    triggerStatus();
    triggerMatches();
    setSelected(0);
  }, [triggerMatches, triggerStatus, setSelected]);

  useEffect(() => {
    if (selfServiceScreening && !screeningStatus?.includes('Complete')) {
      triggerMatches();
    }
  }, [selfServiceScreening, id, triggerMatches, screeningStatus]);

  const isScreeningOrder = statusData?.dueDiligenceType?.includes('Screening');

  const isReportReady =
    screeningStatus === 'Report ready for review' ||
    screeningStatus === 'Updated report ready for review';

  const isInProgress = screeningStatus === 'In progress';

  const handleDownload = async () => {
    const response = await fetch(`${vantageWebApi}/onlinereport/${id}/file`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${msalSubscriptionKey}`,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const file = await response.clone().blob();
      downloadBlob(document.body, file, statusData.reportFileName);
    } else {
      response.status, response.statusText, await response.json();
    }
  };

  let tabCtas = [];

  if (isScreeningOrder) {
    if (
      !(isChildReport && !hasPermission(GatewayPermissions.ViewDocuments)) &&
      !isInProgress
    ) {
      tabCtas.push({
        i: [0, 1],
        cta: (
          <>
            {getFileExtension(reportFileName)?.toLowerCase() === 'pdf' ||
            reportFileName === null ? (
              <Button
                key="viewReport"
                secondary
                disabled={isErrored}
                onClick={() => {
                  if (isReportReady) setReportOpen(!reportOpen);
                }}
              >
                {isReportReady ? (
                  'View Vantage Report'
                ) : (
                  <Link to={`/report/${id}`}>View vantage report</Link>
                )}
              </Button>
            ) : (
              <DualButton
                key="downloadReport"
                label="Download Vantage Report"
                dropdownLabel="Finalize Review"
                secondary
                onClick={() => {
                  handleDownload();
                }}
                dropdownOnClick={() => {
                  setIsConfirmationModalOpen(!isConfirmationModalOpen);
                }}
              />
            )}
          </>
        ),
      });
    }
  } else {
    if (
      !isInProgress &&
      !screeningStatus?.includes('Complete') &&
      hasPermission(GatewayPermissions.ViewDueDiligenceReport)
    ) {
      tabCtas.push({
        i: [0, 1],
        cta: (
          <>
            {getFileExtension(reportFileName)?.toLowerCase() === 'pdf' ||
            reportFileName === null ? (
              <Button
                key="viewReport"
                secondary
                onClick={() => setReportOpen(!reportOpen)}
              >
                View vantage report
              </Button>
            ) : (
              <DualButton
                key="downloadReport"
                label="Download Vantage Report"
                dropdownLabel="Finalize Review"
                secondary
                dropdownOnClick={() => {
                  setIsConfirmationModalOpen(!isConfirmationModalOpen);
                }}
                onClick={() => {
                  handleDownload();
                }}
              />
            )}
          </>
        ),
      });
    } else if (
      screeningStatus?.includes('Complete') &&
      hasPermission(GatewayPermissions.ViewDueDiligenceReport)
    ) {
      tabCtas.push({
        i: [0, 1],
        cta: (
          <>
            {getFileExtension(reportFileName)?.toLowerCase() === 'pdf' ||
            reportFileName === null ? (
              <Button
                key="viewReport"
                secondary
                onClick={() => setReportOpen(!reportOpen)}
              >
                View vantage report
              </Button>
            ) : (
              <Button
                key="downloadReport"
                label="Download Vantage Report"
                secondary
                onClick={() => {
                  handleDownload();
                }}
              >
                Download Vantage Report
              </Button>
            )}
          </>
        ),
      });
    }
  }

  const showStatusButton =
    selfServiceScreening &&
    (statusData?.state === 'ResolvedMonitoring' ||
      statusData?.state === 'ResolvedClosed') &&
    statusData?.numberOfMatchesFound > 0 &&
    hasPermission(GatewayPermissions.EditCase);

  const showMonitoringButton =
    hasEditMonitoringPermission &&
    canEditMonitoring(typesData, statusData?.dueDiligenceType);

  const showDeleteButton = hasPermission(GatewayPermissions.DeleteThirdParty);

  if (showStatusButton || showMonitoringButton || showDeleteButton) {
    tabCtas.push({
      i: [0, 1],
      cta: (
        <WhiteOutlineButton>
          <Button
            outline
            disabled={isErrored}
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            onClick={() => setIsButtonMenuOpen((isOpen) => !isOpen)}
          >
            Manage Report <Icon icon="chevron-down" size="xxs" colour="white" />
          </Button>
          {isButtonMenuOpen && (
            <ButtonMenu ref={buttonMenuRef}>
              {(showStatusButton || showMonitoringButton) && (
                <ButtonMenuItem className="label">
                  <span className="text">Edit options</span>
                </ButtonMenuItem>
              )}
              {showStatusButton && (
                <ButtonMenuItem
                  title="status"
                  onClick={() => {
                    triggerEditScreening();
                    setIsButtonMenuOpen((isOpen) => !isOpen);
                  }}
                >
                  <StatusIcon />
                  <span className="text">Status</span>
                </ButtonMenuItem>
              )}
              {showMonitoringButton && (
                <ButtonMenuItem
                  title="monitoring"
                  onClick={() => {
                    setEditMonitoringOpen(true);
                    setIsButtonMenuOpen((isOpen) => !isOpen);
                  }}
                >
                  <Icon icon="search" size="xxs" />
                  <span className="text">Monitoring</span>
                </ButtonMenuItem>
              )}
              {showDeleteButton && (
                <ButtonMenuItem
                  title="delete"
                  className="divisor-top"
                  onClick={() => {
                    setIsDeleteConfirmationModalOpen(true);
                    setIsButtonMenuOpen((isOpen) => !isOpen);
                  }}
                >
                  <Icon
                    icon="trash"
                    size="xxs"
                    colour={theme.colours.redRampage}
                  />
                  <span className="text danger">Delete report</span>
                </ButtonMenuItem>
              )}
            </ButtonMenu>
          )}
        </WhiteOutlineButton>
      ),
    });
  }

  if (!isScreeningOrder && statusData) {
    const name = statusData.request
      ? statusData?.request.screeningSearchDetails.name
      : statusData?.thirdParty?.name;
    const subject = `${name} - ${statusData.screeningId}`;
    tabCtas.push({
      i: [0, 1],
      cta: (
        <WhiteOutlineButton key="editMonitoring">
          <Button
            outline
            onClick={() =>
              (window.location.href = `mailto:vantagesupport@controlrisks.com?subject=${subject}`)
            }
          >
            Contact Control Risks
          </Button>
        </WhiteOutlineButton>
      ),
    });
  }

  const showFinalize = () => {
    if (
      statusData?.dueDiligenceType ===
        GatewaySubscriptionTypeNames.ManagedServiceScreening &&
      (hasPermission(GatewayPermissions.ManagedServiceFinalizeReview) ||
        (statusData?.parentReportId &&
          hasPermission(GatewayPermissions.ManagedDueDiligenceFinalizeReview)))
    ) {
      return true;
    }

    if (
      statusData?.dueDiligenceType ===
        GatewaySubscriptionTypeNames.ManagedServiceDueDiligence &&
      hasPermission(GatewayPermissions.ManagedDueDiligenceFinalizeReview) &&
      !(screeningStatus === 'Complete')
    ) {
      return true;
    }

    return false;
  };

  if (statusData?.status === 404) {
    return <Redirect to="/not-found" />;
  }

  if (statusData?.status === 401) {
    return <Redirect to="/unauthorised" />;
  }

  return (
    <AppLayout
      topBarSlot={
        <Link to={`/third-party/profile/${statusData?.thirdParty?.id}`} dark>
          {`Back to ${terminologyConfig?.thirdPartySingularNaming.toLowerCase()} manager`}
        </Link>
      }
    >
      {statusLoading ? (
        <Section>
          <Loading />
        </Section>
      ) : (
        <>
          <h1>
            {`Results for: ${
              statusData?.request?.screeningSearchDetails?.name ||
              statusData?.thirdParty?.name ||
              ''
            }`}
          </h1>
          <Description>
            <p>
              {terminologyConfig?.thirdPartySingularNaming} Case
              <span> - {statusData?.thirdParty?.name}</span>
            </p>
            {statusData?.isRelatedEntity && (
              <p>
                Relationship
                <span> - {statusData?.relationship}</span>
              </p>
            )}
            <p>
              <em>
                {statusData
                  ? isScreeningOrder
                    ? abbreviations[statusData.dueDiligenceType].long
                    : `${abbreviations[statusData.dueDiligenceType].long} - ${
                        statusData.dueDiligenceDetails.reportTemplate.reportName
                      }`
                  : ''}
              </em>
            </p>
            {statusData?.screeningReportStatusId && hasViewTaskList ? (
              <StatusInfo>
                <p>Status: </p>
                <StatusChip color={statusTagColor}>
                  <span>{screeningStatus}</span>
                </StatusChip>
              </StatusInfo>
            ) : (
              ''
            )}
          </Description>
          <Tabs
            setSelected={setSelected}
            selected={selected}
            ctas={tabCtas}
            dark
          >
            <TabContent label={'Target entity details'}>
              <SummaryContainer>
                <SectionSummary
                  summaryList={summary}
                  keepProps={!isScreeningOrder}
                ></SectionSummary>
              </SummaryContainer>
            </TabContent>
            <TabContent
              label={isScreeningOrder ? 'Screening settings' : 'Order Details'}
            >
              <SummaryContainer>
                <SectionSummary
                  summaryList={settings}
                  keepProps={!isScreeningOrder}
                ></SectionSummary>
              </SummaryContainer>
              {statusData?.documents?.length > 0 && (
                <Section large>
                  <DueDiligenceDocuments documents={statusData.documents} />
                </Section>
              )}
            </TabContent>
          </Tabs>
          {statusData?.screeningReportStatusId === 'CompleteNoMatchesFound' && (
            <NoMatchText>No matches found</NoMatchText>
          )}
          {selfServiceScreening && (
            <Matches>
              <FormProvider {...formMethods}>
                <TableContainer>
                  {matchData &&
                    !screeningStatus?.includes('Complete') &&
                    statusData?.state !== 'New' &&
                    !resolveLoading && (
                      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <ScreeningTable
                          matchData={matchData}
                          notes={notes}
                          notesOptions={notesOptions}
                          setNotes={setNotes}
                          activeId={activeProfile?.id}
                          showProfile={showProfile}
                          setPageNumber={setPageNumber}
                          state={statusData?.state}
                        />
                        {matchData?.screening?.numberOfMatchesFound > 0 && (
                          <ButtonWrapper>
                            <Button
                              disabled={
                                !hasPermission(
                                  GatewayPermissions.SelfServiceFinalizeReview
                                )
                              }
                              secondary
                            >
                              Finalise review
                            </Button>
                            {statusData?.state === 'UnresolvedMatches' ||
                              (statusData?.state === 'UnresolvedAlerts' && (
                                <Button
                                  outline
                                  onClick={() => {
                                    triggerUnlockScreening();
                                    history.push(
                                      `/third-party/profile/${statusData?.thirdParty?.id}`
                                    );
                                  }}
                                >
                                  Cancel
                                </Button>
                              ))}
                          </ButtonWrapper>
                        )}
                      </form>
                    )}
                  {error ||
                    (matchData?.status && matchData?.status !== 200 && (
                      <ErrorText>Something isn't working</ErrorText>
                    ))}
                </TableContainer>
                {matchData?.screening?.numberOfMatchesFound > 0 &&
                  activeProfile &&
                  !error && (
                    <ProfileContainer divider style={{ height: 'fit-content' }}>
                      <ProfileDetails
                        details={activeProfile}
                        screeningType={
                          matchData?.screening?.request?.screeningTypeId
                        }
                      />
                    </ProfileContainer>
                  )}
              </FormProvider>
            </Matches>
          )}
          {!selfServiceScreening &&
            isScreeningOrder &&
            !isErrored &&
            statusData && (
              <ProfileContainer>
                <OrderDetails details={statusData} />
              </ProfileContainer>
            )}
          <ScreeningMessage
            dueDiligenceType={statusData?.dueDiligenceType}
            numberOfMatchesFound={matchData?.screening?.numberOfMatchesFound}
            state={screeningStatus}
            numberOfRetries={statusData?.retryCount}
          />
          {resolveLoading && <Loading />}
          {statusData && (
            <EditMonitoringModal
              dueDiligenceTypes={typesData}
              isOpen={editMonitoringOpen}
              onClose={() => setEditMonitoringOpen(false)}
              hasMonitoring={statusData?.hasMonitoring}
              screeningDueDiligenceType={statusData?.dueDiligenceType}
              ongoingScreeningSearchTypeId={
                statusData.ongoingScreeningSearchTypeId
              }
              previousOngoingScreeningSearchTypeId={
                statusData.previousOngoingScreeningSearchTypeId
              }
              screeningId={id}
              reload={useReload}
            />
          )}
          {reportOpen && (
            <ReportModal
              approvalStatusModalEnabled={approvalStatusModalEnabled ?? false}
              isOpen={reportOpen}
              onClose={() => {
                setReportOpen(!reportOpen);
              }}
              onFinalizeClick={() => {
                onFinalizeClick();
              }}
              onApproveClick={() => {
                onApproveClick();
              }}
              isPdf={!isScreeningOrder}
              showFinalise={showFinalize()}
            />
          )}
        </>
      )}
      {isDeleteConfirmationModalOpen && (
        <DueDiligenceDeleteModal
          isOpen={true}
          isMDD={isMDD}
          screeningId={statusData.screeningId}
          thirdPartyId={statusData.thirdParty.id}
          onClose={() => setIsDeleteConfirmationModalOpen(false)}
        />
      )}
      <Modal
        title="Are you sure?"
        isOpen={isConfirmationModalOpen}
        onClose={null}
      >
        <ReportProceedingModalContainer>
          <ModalParagraph>
            Please note that this action will set the review status to
            'Complete' and remove the outstanding action from the Task list.
          </ModalParagraph>
          {finalizeLoading ? (
            <Loading />
          ) : (
            <ButtonContainer>
              <Button
                outline={true}
                className="button"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="button"
                onClick={() => {
                  finalizeLazy();
                }}
              >
                Proceed
              </Button>
            </ButtonContainer>
          )}
        </ReportProceedingModalContainer>
      </Modal>
      <Modal
        title="Update approval status"
        isOpen={isApprovalModalOpen}
        onClose={onFinalizeClick}
      >
        <ReportProceedingModalContainer>
          <ModalParagraph>
            Do you want to update the approval status of this third party?
          </ModalParagraph>
          {finalizeLoading ? (
            <Loading />
          ) : (
            <ButtonContainer>
              <Button
                className="button"
                outline={true}
                onClick={onFinalizeClick}
              >
                No
              </Button>
              <Button className="button" onClick={onApproveClick}>
                Yes - Update
              </Button>
            </ButtonContainer>
          )}
        </ReportProceedingModalContainer>
      </Modal>
    </AppLayout>
  );
}

export default withAITracking(reactPlugin, ThirdPartyScreening);
