import React, { FC, PropsWithChildren } from 'react';
import {
  PreviewContainer,
  UploadBoxContainer,
} from './DocumentUploadModal.styles';
import { ReactComponent as UploadSvg } from 'assets/svg/Icons/Document Upload.svg';
import { Typography } from 'components/atoms';
import { UploadFileDashedContainerTypes } from './types';
import DocumentCard from 'components/molecules/document-card';
import { FileFormat, getFileExtension } from 'helpers/documentHelper';

const UploadFileDashedContainer: FC<
  PropsWithChildren<UploadFileDashedContainerTypes>
> = ({
  valid,
  selectedFile,
  supportedFormats,
  documentCategory,
  onRemoveDocument,
  children,
}) => {
  return (
    <>
      <UploadBoxContainer error={!valid}>
        <UploadSvg />
        <Typography
          className="upload-info-text"
          value={`Supported formats: ${supportedFormats}`}
          tag="p"
        />
        <Typography
          className="upload-info-text"
          value="Max size: 20MB"
          tag="p"
        />
        {children}
      </UploadBoxContainer>
      {selectedFile && valid && (
        <PreviewContainer>
          <DocumentCard
            fileType={
              getFileExtension(selectedFile.name).toLowerCase() as FileFormat
            }
            name={selectedFile.name}
            date={new Date().toDateString()}
            category={documentCategory}
            option="withRemove"
            onOptionClick={onRemoveDocument}
          />
        </PreviewContainer>
      )}
    </>
  );
};

export default UploadFileDashedContainer;
