import React, { FC } from 'react';
import {
  ProfileContent,
  ContentSection,
  ContentTitle,
  ContentContainer,
  ItemMatch,
  QuickLinks,
  QuickLinksContainer,
  ModalFooter,
  ProfileNotes,
  SourceLinks,
  MessageContainer,
} from './ProfileModal.styles';
import {
  Button,
  Date,
  DescriptionList,
  FieldRadio,
  Typography,
} from 'components/atoms';
import { ProfileModalTypes } from './types';
import { Message, Modal } from 'components/molecules';
import { useFormContext } from 'react-hook-form';

const ProfileModal: FC<ProfileModalTypes> = ({
  matchData,
  screeningType,
  isOpen,
  onClose,
}: ProfileModalTypes) => {
  const {
    id,
    ids,
    addresses,
    citizenship,
    dateOfBirth,
    gender,
    primaryName,
    matchedName,
    placeOfBirth,
    profileId,
    profileLastUpdatedDate,
    profileMatchedDate,
    profileCreatedDate,
    matchUpdates,
    matchedCriteria,
    matchReasonDetails,
    source,
    sanctions,
    summaryOfInclusion,
    akAs,
    notes,
    associationsSummary,
    sourcesOfRecordInformation,
  } = matchData || {
    id: undefined,
    profileId: undefined,
    akAs: [],
    primaryName: undefined,
    matchedName: undefined,
    category: undefined,
    subCategory: undefined,
    summaryOfInclusion: undefined,
    dateOfBirth: undefined,
    gender: undefined,
    placeOfBirth: undefined,
    addresses: [],
    profileMatchedDate: undefined,
    profileLastUpdatedDate: undefined,
    sanctions: [],
    ids: [],
    associationsSummary: [],
    sourcesOfRecordInformation: '',
    citizenship: '',
    matchedCriteria: [],
    matchUpdates: [],
  };

  const filteredMatchUpdates = matchUpdates.filter((el) => {
    if (el.addedInformation || el.removedInformation) {
      return true;
    }
    return false;
  });

  const ContentSectionComponent = ({
    children,
    title,
    id,
  }: {
    children: any;
    title?: string;
    id?: string;
  }) => (
    <ContentSection>
      {title && (
        <div id={id}>
          <ContentTitle tag="h2" value={title} />
        </div>
      )}
      <ContentContainer>{children}</ContentContainer>
    </ContentSection>
  );

  const camelCaseToSentenceCase = (text) => {
    if (text.match(/([A-Z]{2,})/)) {
      return text;
    }
    const result = text.replace(/([A-Z])/g, ' $1');
    const final = result.charAt(0).toUpperCase() + result.slice(1);
    return final;
  };

  const { setValue, watch } = useFormContext();
  const isPossibleMatch =
    watch(`matches.match_${id}.state`) === 'PossibleMatch';
  const isConfirmedMatch = watch(`matches.match_${id}.state`) === 'Match';

  return (
    <Modal isOpen={isOpen} onClose={onClose} opaqueOverlay fullScreen>
      {filteredMatchUpdates.length > 0 && (
        <MessageContainer>
          <Message
            icon="attention"
            message={`${filteredMatchUpdates.length} ${
              filteredMatchUpdates.length === 1 ? 'change has' : 'changes have'
            } been made to this profile since its last review`}
            highlighted
            large
          />
        </MessageContainer>
      )}
      <ProfileContent>
        {filteredMatchUpdates.length > 0 && (
          <>
            <ContentSectionComponent title="Added Information">
              <DescriptionList
                items={filteredMatchUpdates
                  ?.filter((update) => update.addedInformation)
                  ?.map((update) => ({
                    key: camelCaseToSentenceCase(update.propertyName),
                    value: update.addedInformation.split('|').join('\n\n'),
                  }))}
                spaced
                titleFontWeight={'900'}
                detailsMargin={false}
                detailsFlexDirection={'column'}
              />
            </ContentSectionComponent>
            <ContentSectionComponent title="Removed Information">
              <DescriptionList
                items={filteredMatchUpdates
                  ?.filter((update) => update.removedInformation)
                  ?.map((update) => ({
                    key: camelCaseToSentenceCase(update.propertyName),
                    value: (
                      <del>
                        {update.removedInformation.split('|').join('\n\n')}
                      </del>
                    ),
                  }))}
                spaced
                titleFontWeight={'900'}
                detailsMargin={false}
                detailsFlexDirection={'column'}
              />
            </ContentSectionComponent>
          </>
        )}
        <ContentSectionComponent title="Profile Summary">
          <DescriptionList
            items={[
              { key: 'Primary Name', value: primaryName },
              { key: 'Matched Name', value: matchedName },
              { key: 'Entity', value: screeningType },
              { key: 'Profile ID', value: profileId },
              {
                key: 'Last updated date',
                value: profileLastUpdatedDate && (
                  <Date dateTime={profileLastUpdatedDate} />
                ),
              },
              {
                key: 'Source Date',
                value: profileMatchedDate && (
                  <Date dateTime={profileMatchedDate} />
                ),
              },
              {
                key: 'Profile Creation Date',
                value: profileCreatedDate && (
                  <Date dateTime={profileCreatedDate} />
                ),
              },
            ]}
            spaced
          />
        </ContentSectionComponent>

        <ContentSectionComponent title="Match Summary">
          <DescriptionList
            items={matchedCriteria.map(({ input, actual, score = 0 }) => ({
              key: input,
              value: actual,
              separator: score === 100 ? ' =' : ' ≈',
            }))}
            spaced
          />
          <ItemMatch>
            <FieldRadio
              label="Possible"
              id={'possible-' + id}
              value="PossibleMatch"
              name={'match-' + id}
              showBorder={true}
              labelRight={true}
              small={true}
              checked={isPossibleMatch}
              onChange={() =>
                setValue(`matches.match_${id}.state`, 'PossibleMatch')
              }
            >
              <span>Possible</span>
            </FieldRadio>
            <FieldRadio
              label="Confirmed"
              id={'confirmed-' + id}
              value="Match"
              name={'match-' + id}
              showBorder={true}
              labelRight={true}
              small={true}
              checked={isConfirmedMatch}
              onChange={() => setValue(`matches.match_${id}.state`, 'Match')}
            >
              <span>Confirmed</span>
            </FieldRadio>
          </ItemMatch>
        </ContentSectionComponent>
      </ProfileContent>

      <QuickLinks>
        <Typography tag="h2" value="Quick Links" />
        <QuickLinksContainer>
          <Button as="a" outline href="#reasonForInclusion">
            Reasons for inclusion
          </Button>
          <Button as="a" outline href="#keyIdentifyingInfo">
            Key Identifying info
          </Button>
          <Button as="a" outline href="#akAs">
            AKAs
          </Button>
          <Button as="a" outline href="#addresses">
            Addresses
          </Button>
          <Button as="a" outline href="#profileNotes">
            Profile Notes
          </Button>
          <Button as="a" outline href="#associations">
            Associations
          </Button>
          <Button as="a" outline href="#originalSourceLinks">
            Original Source Links
          </Button>
        </QuickLinksContainer>
      </QuickLinks>

      <ProfileContent>
        <ContentSectionComponent
          id="reasonForInclusion"
          title="Reasons for inclusion"
        >
          <DescriptionList
            items={[
              ...(Array.isArray(matchReasonDetails) &&
              matchReasonDetails?.length > 0
                ? [
                    {
                      key: 'Reasons Listed',
                      value: matchReasonDetails.map((reason) => (
                        <div key={reason}>
                          <span>{reason}</span>
                        </div>
                      )),
                    },
                  ]
                : []),
              { key: 'Summary', value: summaryOfInclusion },
              { key: 'Source', value: source },
              {
                key: 'Sanctions Only',
                value:
                  Array.isArray(sanctions) &&
                  sanctions?.length > 0 &&
                  sanctions.map((sanction) => (
                    <div key={sanction}>
                      <span>{sanction}</span>
                    </div>
                  )),
              },
            ]}
            spaced
          />
        </ContentSectionComponent>

        <ContentSectionComponent
          id="keyIdentifyingInfo"
          title="Key Identifying Info"
        >
          <DescriptionList
            items={[
              {
                key: 'Date of Birth',
                value: dateOfBirth || ' ',
              },
              {
                key: 'Gender',
                value: gender || ' ',
              },
              {
                key: 'Place of Birth',
                value: placeOfBirth || ' ',
              },
              {
                key: 'Citizenship',
                value: citizenship || ' ',
              },
              {
                key: 'IDs',
                value: Array.isArray(ids) && ids.join(', '),
              },
            ]}
          />
        </ContentSectionComponent>

        {Array.isArray(akAs) && akAs?.length > 0 && (
          <ContentSectionComponent id="akAs" title="AKAs">
            <DescriptionList
              items={akAs.map((aka, index) => ({
                key: `${index + 1}`,
                value: aka,
              }))}
            />
          </ContentSectionComponent>
        )}

        <ContentSectionComponent id="profileNotes" title="Profile Notes">
          <ProfileNotes>{notes}</ProfileNotes>
        </ContentSectionComponent>

        <ContentSectionComponent id="addresses" title="Addresses">
          {Array.isArray(addresses) && (
            <DescriptionList
              items={addresses.flatMap(
                ({ addressLineOne, city, district, postCode, country }) => [
                  {
                    key: 'Address',
                    value: [addressLineOne, city, district, postCode]
                      .filter((value) => value)
                      .join(', '),
                  },
                  { key: 'Country', value: country },
                ]
              )}
            />
          )}
        </ContentSectionComponent>

        <ContentSectionComponent id="associations" title="Associations">
          {Array.isArray(associationsSummary) && (
            <DescriptionList
              items={associationsSummary.map((association, index) => ({
                key: `${index + 1}`,
                value: association,
              }))}
              spaced
            />
          )}
        </ContentSectionComponent>

        <ContentSectionComponent
          id="originalSourceLinks"
          title="Original Source Links"
        >
          <SourceLinks>
            {sourcesOfRecordInformation &&
              sourcesOfRecordInformation
                .split('|')
                .map((url, index) => (
                  <a
                    key={`${index}-${url}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={url}
                  >
                    {url}
                  </a>
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
          </SourceLinks>
        </ContentSectionComponent>
      </ProfileContent>
      <ModalFooter>
        <ItemMatch>
          <FieldRadio
            label="Possible"
            id={'possible-bottom' + id}
            value="PossibleMatch"
            name={'match-bottom' + id}
            showBorder={true}
            labelRight={true}
            small={true}
            checked={isPossibleMatch}
            onChange={() =>
              setValue(`mmatches.match_${id}.state`, 'PossibleMatch')
            }
          >
            <span>Possible</span>
          </FieldRadio>
          <FieldRadio
            label="Confirmed"
            id={'confirmed-bottom' + id}
            value="Match"
            name={'match-bottom' + id}
            showBorder={true}
            labelRight={true}
            small={true}
            checked={isConfirmedMatch}
            onChange={() => setValue(`matches.match_${id}.state`, 'Match')}
          >
            <span>Confirmed</span>
          </FieldRadio>
        </ItemMatch>
        <Button secondary onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileModal;
