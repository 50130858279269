import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { phoneNumber, alphanumeric } from 'helpers/yup-validations';
import dayjs from 'dayjs';

import { useConfig } from 'config';
import { useAuth } from 'auth';
import { useFetch } from 'hooks';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { FixedWidthButton, ActionButton, ArrayItem } from 'form/form.styles';
import Dialog from 'components/molecules/dialog';
import { Icon } from 'components/atoms';

import {
  Button,
  FieldDate,
  FieldInput,
  FieldSelect,
  FieldTextarea,
  FormControl,
  Loading,
} from 'components/atoms';
import { Radio } from 'components/atoms/field-radio/FieldRadio';
import { ButtonWrapper } from 'form/form.styles';
import {
  Column,
  ColumnsContainer,
  EditForm,
  FormHeader,
  RadioGroup,
  Separator,
  Wrapper,
} from './ThirdPartyEdit.styles';
import { setNullWhenIsEmpty } from 'helpers/strings-functions';
import {
  ThirdPartyDataConfigDictionary,
  getThirdPartyConfiguration,
} from 'services/getThirdPartyConfiguration';
import { ThirdPartyType } from 'types/thirdPartyType';
import { ThirdParty } from '../third-party-overview-side-module/types';
import { useCountryHelper } from 'helpers/countriesHelper';

interface ThirdPartyEditProps {
  inModal: boolean;
  thirdParty?: ThirdParty;
  id?: string;
  onCancel: () => void;
  onSave: (thirdParty: Partial<ThirdParty>) => void;
}

const ThirdPartyEdit = ({
  onCancel,
  onSave,
  id,
  thirdParty,
  inModal,
}: ThirdPartyEditProps) => {
  const { accessToken, gatewayUserId } = useAuth();
  const { vantageWebApi, msalSubscriptionKey } = useConfig();
  const terminologyConfig = useTerminologyConfig();

  const { id: idParam } = useParams<{ id: string }>();

  const [showDialog, setShowDialog] = useState(false);
  const [thirdPartyDataConfig, setThirdPartyDataConfig] =
    useState<ThirdPartyDataConfigDictionary | null>(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const thirdPartyData = thirdParty;

  const headers = useMemo(
    () => ({
      'Ocp-Apim-Subscription-Key': msalSubscriptionKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    [msalSubscriptionKey, accessToken]
  );

  const thirdPartyId = id ?? idParam;

  const {
    data: ownersOptions,
    loading: isOwnerLoading,
    error: hasOwnerError,
  } = useFetch(
    `${vantageWebApi}/account/team`,
    { method: 'GET', headers },
    'json'
  );

  const isBusiness = useMemo(
    () => thirdPartyData?.type === ThirdPartyType.Business,
    [thirdPartyData]
  );
  const isIndividual = useMemo(
    () => thirdPartyData?.type === ThirdPartyType.Individual,
    [thirdPartyData]
  );
  const { sortedCountries: countries } = useCountryHelper();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        name: alphanumeric.max(140, 'Maximum 140 characters').when([], {
          is: () => thirdPartyDataConfig.name.isMandatory,
          then: (schema) =>
            schema.required(
              `Please enter ${isBusiness ? 'a company' : 'an individual'} name`
            ),
        }),
        alternativeName: alphanumeric
          .max(140, 'Maximum 140 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.alternativeName.isMandatory,
            then: (schema) =>
              schema.required(
                `Please enter ${
                  isBusiness
                    ? 'an alternative company'
                    : 'an alternative individual'
                } name`
              ),
          }),
        primaryCountry: yup.mixed().when([], {
          is: () => thirdPartyDataConfig.primaryCountry.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        internalId: yup.string().required('This field is required'),
        duns: yup
          .string()
          .matches(/^[0-9]+$/, 'DUNS needs to be a number')
          .max(19, 'Maximum 19 characters')
          .nullable()
          .when([], {
            is: () => isBusiness && thirdPartyDataConfig.duns.isMandatory,
            then: (schema) => schema.required(`Please enter DUNS`),
          }),
        addressLineOne: yup
          .string()
          .max(140, 'Maximum 140 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.addressLineOne.isMandatory,
            then: (schema) => schema.required(`Please enter an address`),
          }),
        city: yup
          .string()
          .max(60, 'Maximum 60 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.city.isMandatory,
            then: (schema) => schema.required(`Please enter a city`),
          }),
        county: yup
          .string()
          .max(60, 'Maximum 60 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.county.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a state, region and/or province`),
          }),
        zipCode: yup
          .string()
          .max(10, 'Maximum 10 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.zipCode.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a post code or zip code`),
          }),
        countryTwo: yup.mixed().when([], {
          is: () => thirdPartyDataConfig.countryTwo.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        countryThree: yup.mixed().when([], {
          is: () => thirdPartyDataConfig.countryThree.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        gender: yup
          .number()
          .nullable()
          .when([], {
            is: () => isIndividual && thirdPartyDataConfig.gender.isMandatory,
            then: (schema) => schema.required(`Please enter a gender`),
          }),
        dateOfBirth: yup
          .date()
          .nullable()
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.dateOfBirth.isMandatory,
            then: (schema) => schema.required(`Please enter a date of birth`),
          }),
        nationality: yup.mixed().when([], {
          is: () =>
            isIndividual && thirdPartyDataConfig.nationality.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        passportNumber: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .nullable()
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.passportNumber.isMandatory,
            then: (schema) => schema.required(`Please enter a passport number`),
          }),
        nationalIDNumber: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .nullable()
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.nationalIDNumber.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a national ID number`),
          }),
        otherIDNumber: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .nullable()
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.otherIDNumber.isMandatory,
            then: (schema) => schema.required(`Please enter other ID number`),
          }),
        registrationNumber: yup
          .string()
          .max(25, 'Maximum 25 characters')
          .nullable()
          .when([], {
            is: () =>
              isBusiness && thirdPartyDataConfig.registrationNumber.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a registration number`),
          }),
        swift: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .nullable()
          .when([], {
            is: () => isBusiness && thirdPartyDataConfig.swift.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a SWIFT or BIC number`),
          }),
        companyUrl: yup
          .string()
          .max(255, 'Maximum 255 characters')
          .nullable()
          .when([], {
            is: () => isBusiness && thirdPartyDataConfig.companyUrl.isMandatory,
            then: (schema) => schema.required(`Please enter a company website`),
          }),
        ownerIds: yup
          .array()
          .test(
            'duplicatedOwnerIds',
            `The same user has been selected as an ${terminologyConfig?.internalOwnerSingularNaming} more than once. Please remove duplication`,
            (ownerIds) => {
              const ownerIdsToCheck: string[] = ownerIds
                .filter(({ value }) => value !== 'DEFAULT')
                .map((obj) => obj.value);

              return new Set(ownerIdsToCheck).size === ownerIdsToCheck.length;
            }
          ),
        nextReviewDate: yup
          .date()
          .nullable()
          .when([], {
            is: () =>
              thirdPartyDataConfig.nextReviewDate.isEnabled &&
              thirdPartyDataConfig.nextReviewDate.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a next review date`),
          }),
        referenceField1: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.referenceField1.isMandatory,
            then: (schema) => schema.required(`Please enter a reference`),
          }),
        referenceField2: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.referenceField2.isMandatory,
            then: (schema) => schema.required(`Please enter a reference`),
          }),
        referenceField3: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.referenceField3.isMandatory,
            then: (schema) => schema.required(`Please enter a reference`),
          }),
        keyFullName: yup
          .string()
          .max(40, 'Maximum 40 characters')
          .nullable()
          .when([], {
            is: () =>
              isBusiness && thirdPartyDataConfig.keyFullName.isMandatory,
            then: (schema) => schema.required(`Please enter a contact name`),
          }),
        keyEmail: yup
          .string()
          .email('You must enter a valid email address')
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.keyEmail.isMandatory,
            then: (schema) => schema.required(`Please enter a contact email`),
          }),
        keyPhoneNumber: phoneNumber.max(20, 'Maximum 20 characters').when([], {
          is: () => thirdPartyDataConfig.keyPhoneNumber.isMandatory,
          then: (schema) => schema.required(`Please enter a contact phone`),
        }),
        internalNotes: yup
          .string()
          .max(2000, 'Maximum 2000 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig?.internalNotes?.isMandatory,
            then: (schema) => schema.required(`Please enter internal notes`),
          }),
      })
    ),
    defaultValues: {
      ownerIds: [],
      nextReviewDate: null,
      countryTwo: null,
      countryThree: null,
      gender: null,
      nationality: null,
      dateOfBirth: null,
      keyPhoneNumber: null,
      name: null,
      alternativeName: null,
      internalId: null,
      addressLineOne: null,
      city: null,
      county: null,
      zipCode: null,
      primaryCountry: null,
      duns: null,
      passportNumber: null,
      nationalIDNumber: null,
      otherIDNumber: null,
      registrationNum: null,
      swift: null,
      companyUrl: null,
      keyFullName: null,
      keyEmail: null,
      internalNotes: null,
      registrationNumber: null,
      referenceField1: null,
      referenceField2: null,
      referenceField3: null,
    },
  });

  const {
    fields: ownerFields,
    append: ownerAppend,
    remove: ownerRemove,
  } = useFieldArray({
    control,
    name: 'ownerIds',
  });

  const parseData = (data) => {
    data.countryTwo = data?.countryTwo === 'DEFAULT' ? null : data?.countryTwo;
    data.countryThree =
      data?.countryThree === 'DEFAULT' ? null : data?.countryThree;
    data.gender = parseInt(data?.gender);
    data.ownerIds = data?.ownerIds
      ?.filter(({ value }) => value !== 'DEFAULT')
      ?.map(({ value }) => parseInt(value));
    data.duns = parseInt(data?.duns);
    data.companyUrl = setNullWhenIsEmpty(data?.companyUrl);
    data.keyFullName = setNullWhenIsEmpty(data?.keyFullName);
    data.keyPhoneNumber = setNullWhenIsEmpty(data?.keyPhoneNumber);
    data.keyEmail = setNullWhenIsEmpty(data?.keyEmail);
    data.dateOfBirth = data?.dateOfBirth
      ? new Date(data?.dateOfBirth).toLocaleDateString('fr-CA')
      : null;
    data.nextReviewDate = data?.nextReviewDate
      ? new Date(data?.nextReviewDate).toLocaleDateString('fr-CA') // we are using fr-CA to establish format yyyy-mm-dd since we were usig ISO standards
      : null;

    return JSON.stringify(data);
  };

  const postEdit = async (data) => {
    const editResponse = await fetch(
      `${vantageWebApi}/thirdParty/${thirdPartyId}/edit`,
      {
        method: 'POST',
        headers,
        body: parseData(data),
      }
    );

    if (!editResponse.ok) {
      //setSubmitError(true);
    }

    return await editResponse.json();
  };

  useEffect(() => {
    if (!thirdPartyData) {
      return;
    }

    const { type } = thirdPartyData;
    getThirdPartyConfiguration(vantageWebApi, headers, type).then((config) =>
      setThirdPartyDataConfig(config)
    );

    const formValues = {
      name: thirdPartyData.name,
      alternativeName: thirdPartyData.alternativeName,
      internalId: thirdPartyData.internalId,
      addressLineOne: thirdPartyData.addressLineOne,
      city: thirdPartyData.city,
      county: thirdPartyData.county,
      zipCode: thirdPartyData.zipCode,
      primaryCountry: thirdPartyData.primaryCountry ?? 'DEFAULT',
      countryTwo: thirdPartyData.countryTwo ?? 'DEFAULT',
      countryThree: thirdPartyData.countryThree ?? 'DEFAULT',
      gender:
        typeof thirdPartyData.gender === 'number'
          ? String(thirdPartyData.gender)
          : '2',
      dateOfBirth: thirdPartyData.dateOfBirth ?? undefined,
      nationality: thirdPartyData.nationality ?? 'DEFAULT',
      duns: thirdPartyData.duns,
      passportNumber: thirdPartyData.passportNumber,
      nationalIDNumber: thirdPartyData.nationalIDNumber,
      otherIDNumber: thirdPartyData.otherIDNumber,
      registrationNumber: thirdPartyData.registrationNumber,
      swift: thirdPartyData.swift,
      companyUrl: thirdPartyData.companyWebsite,
      keyFullName: thirdPartyData.keyFullName,
      keyEmail: thirdPartyData.keyEmail,
      nextReviewDate: thirdPartyData.nextReviewDate ?? null,
      keyPhoneNumber: thirdPartyData.keyPhoneNumber,
      referenceField1: thirdPartyData.referenceField1,
      referenceField2: thirdPartyData.referenceField2,
      referenceField3: thirdPartyData.referenceField3,
      internalNotes: thirdPartyData.internalNotes,
    };
    reset(formValues);
  }, [thirdPartyData, reset, vantageWebApi, headers]);

  useEffect(() => {
    if (!thirdPartyDataConfig) return;

    const defaultOwnerId =
      thirdPartyDataConfig?.autoAssignDefaultInternalOwner?.configurationValue;
    let ownersData = thirdPartyData.thirdPartyOwners ?? [];
    if (defaultOwnerId) {
      const defaultOwner = ownersData.find(
        (owner) => String(owner.users.id) === defaultOwnerId
      );
      const otherOwners = ownersData.filter(
        (owner) => String(owner.users.id) !== defaultOwnerId
      );

      ownersData = defaultOwner
        ? [defaultOwner, ...otherOwners]
        : thirdPartyData.thirdPartyOwners;
    }
    setValue(
      'ownerIds',
      ownersData?.map(({ users }) => ({
        value: String(users?.id) || 'DEFAULT',
      })) || []
    );
  }, [
    thirdPartyDataConfig,
    headers,
    reset,
    vantageWebApi,
    setValue,
    thirdPartyData?.thirdPartyOwners,
  ]);

  const permissionToRemoveThemselvesEnabled = useCallback(
    (ownerId: string): boolean => {
      return !(
        thirdPartyDataConfig?.allowRemoveMyselfAsOwner?.isEnabled === false &&
        ownerId === gatewayUserId
      );
    },
    [thirdPartyDataConfig, gatewayUserId]
  );

  const isDefaultOwner = useCallback(
    (ownerId: string, index: number) => {
      return (
        ownerId ===
          thirdPartyDataConfig?.autoAssignDefaultInternalOwner
            ?.configurationValue && index === 0
      );
    },
    [thirdPartyDataConfig]
  );

  const showAddOwnersButton = useMemo(() => {
    return (
      thirdPartyDataConfig?.allowAddOtherOwners?.isEnabled &&
      ownerFields.length < 3
    );
  }, [thirdPartyDataConfig, ownerFields]);

  const isValid = (field: string) => {
    return !errors?.[field]?.message && !touchedFields?.[field]?.message;
  };

  const onSubmit = () => setShowDialog(true);

  const handleAddOwner = () => {
    ownerAppend({ value: 'DEFAULT' });
  };

  const handleRemoveOwner = (index) => {
    ownerRemove(index);
  };

  const handleConfirmDialog = async () => {
    setShowDialog(true);
    const serverData = await postEdit(getValues());
    onSave(serverData);
    setIsSubmitLoading(false);
    setShowDialog(false);
  };

  const handleDeclineDialog = () => {
    setShowDialog(false);
  };

  if (hasOwnerError) {
    return <h1> ERROR </h1>;
  }

  return (
    <Wrapper>
      {isOwnerLoading || !thirdPartyDataConfig ? (
        <Loading />
      ) : (
        <>
          <FormHeader hidden={inModal}>
            {`Edit ${terminologyConfig?.thirdPartySingularNaming.toLowerCase()} details`}
          </FormHeader>
          <EditForm onSubmit={handleSubmit(onSubmit)}>
            <ColumnsContainer>
              <Column>
                <FormControl errorMessage={errors.name?.message}>
                  <FieldInput
                    label="Name"
                    id="name"
                    name="name"
                    register={register}
                    fullWidth
                    valid={isValid('name')}
                    required={thirdPartyDataConfig.name.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.alternativeName?.message}>
                  <FieldInput
                    label="Name (alternative script)"
                    id="alternativeName"
                    name="alternativeName"
                    register={register}
                    fullWidth
                    valid={isValid('alternativeName')}
                    required={thirdPartyDataConfig.alternativeName.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.internalId?.message}>
                  <FieldInput
                    label={terminologyConfig?.internalIdSingularNaming}
                    id="internalId"
                    name="internalId"
                    register={register}
                    disabled
                    fullWidth
                    valid={isValid('internalId')}
                    required={thirdPartyDataConfig.internalId.isMandatory}
                  />
                </FormControl>
                {thirdPartyDataConfig.nextReviewDate?.isEnabled && (
                  <FormControl errorMessage={errors.nextReviewDate?.message}>
                    <Controller
                      control={control}
                      name="nextReviewDate"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FieldDate
                            label="Next review date"
                            value={dayjs(value).format('DD/MM/YYYY')}
                            onChange={(date) => {
                              onChange(date ? new Date(date) : null);
                            }}
                            fullWidth
                            format="DD/MM/YYYY"
                            valid={isValid('nextReviewDate')}
                            required={
                              thirdPartyDataConfig.nextReviewDate.isMandatory
                            }
                          />
                        );
                      }}
                    />
                  </FormControl>
                )}
                <FormControl>
                  {ownerFields.map((field, index) => {
                    return (
                      <ArrayItem key={field.id}>
                        <FormControl
                          style={{ flex: 1 }}
                          errorMessage={(errors?.ownerIds as any)?.message}
                        >
                          <FieldSelect
                            label={`${terminologyConfig?.internalOwnerSingularNaming}`}
                            id={`ownerIds.${index}.value`}
                            key={`ownerIds.${index}.value`}
                            register={register}
                            options={ownersOptions?.map((owner) => ({
                              name: `${owner.firstName} ${owner.lastName}${
                                owner.company ? ` (${owner.company})` : ''
                              }`,
                              value: owner.id,
                            }))}
                            placeholder={`Please select an ${terminologyConfig?.internalOwnerSingularNaming.toLowerCase()}`}
                            placeholderValue="DEFAULT"
                            fullWidth
                            disabled={
                              !permissionToRemoveThemselvesEnabled(
                                field.value
                              ) || isDefaultOwner(field.value, index)
                            }
                          />
                        </FormControl>
                        {permissionToRemoveThemselvesEnabled(field.value) &&
                          !isDefaultOwner(field.value, index) && (
                            <ActionButton
                              type="button"
                              onClick={handleRemoveOwner}
                            >
                              <Icon icon="trash" size="xs" />
                            </ActionButton>
                          )}
                      </ArrayItem>
                    );
                  })}
                  {showAddOwnersButton && (
                    <ActionButton type="button" onClick={handleAddOwner}>
                      <Icon className="icon" icon="plus-circle" size="xs" />
                      Add another&nbsp;
                      {terminologyConfig?.internalOwnerSingularNaming}
                    </ActionButton>
                  )}
                </FormControl>
                <FormControl errorMessage={errors.addressLineOne?.message}>
                  <FieldInput
                    label="Address"
                    id="addressLineOne"
                    name="addressLineOne"
                    register={register}
                    fullWidth
                    valid={isValid('addressLineOne')}
                    required={thirdPartyDataConfig.addressLineOne.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.city?.message}>
                  <FieldInput
                    label="City"
                    id="city"
                    name="city"
                    register={register}
                    fullWidth
                    valid={isValid('city')}
                    required={thirdPartyDataConfig.city.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.county?.message}>
                  <FieldInput
                    label="State / region / province"
                    id="county"
                    name="county"
                    register={register}
                    fullWidth
                    valid={isValid('county')}
                    required={thirdPartyDataConfig.county.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.zipCode?.message}>
                  <FieldInput
                    label="Postcode / Zip code"
                    id="zipCode"
                    name="zipCode"
                    register={register}
                    fullWidth
                    valid={isValid('zipCode')}
                    required={thirdPartyDataConfig.zipCode.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.primaryCountry?.message}>
                  <FieldSelect
                    label="Primary country"
                    id="primaryCountry"
                    register={register}
                    options={countries}
                    placeholder="Please select a country"
                    placeholderValue="DEFAULT"
                    fullWidth
                    valid={isValid('primaryCountry')}
                    required={thirdPartyDataConfig.primaryCountry.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.countryTwo?.message}>
                  <FieldSelect
                    label="Country 2"
                    id="countryTwo"
                    register={register}
                    options={countries}
                    placeholderValue="DEFAULT"
                    placeholder="Please select a country"
                    fullWidth
                    valid={isValid('countryTwo')}
                    required={thirdPartyDataConfig.countryTwo.isMandatory}
                  />
                </FormControl>
              </Column>
              <Separator />
              <Column>
                <FormControl errorMessage={errors.countryThree?.message}>
                  <FieldSelect
                    label="Country 3"
                    id="countryThree"
                    placeholderValue="DEFAULT"
                    register={register}
                    options={countries}
                    placeholder="Please select a country"
                    fullWidth
                    valid={isValid('countryThree')}
                    required={thirdPartyDataConfig.countryThree.isMandatory}
                  />
                </FormControl>
                {isIndividual && (
                  <FormControl errorMessage={errors.gender?.message}>
                    <label>
                      Gender{' '}
                      {thirdPartyDataConfig.gender.isMandatory && (
                        <span className="required"> *</span>
                      )}
                    </label>
                    <RadioGroup>
                      <Radio
                        label="Unknown"
                        id="unknown"
                        value="2"
                        name="gender"
                        register={register}
                        required={errors.gender?.message}
                      >
                        <span>Unknown</span>
                      </Radio>
                      <Radio
                        label="Male"
                        id="male"
                        value="0"
                        name="gender"
                        register={register}
                        required={errors.gender?.message}
                      >
                        <span>Male</span>
                      </Radio>
                      <Radio
                        label="Female"
                        id="female"
                        value="1"
                        name="gender"
                        register={register}
                        required={errors.gender?.message}
                      >
                        <span>Female</span>
                      </Radio>
                    </RadioGroup>
                  </FormControl>
                )}
                {isIndividual && (
                  <FormControl errorMessage={errors.dateOfBirth?.message}>
                    <Controller
                      control={control}
                      name="dateOfBirth"
                      render={({ field }) => (
                        <FieldDate
                          label="Date of birth"
                          value={
                            field.value
                              ? dayjs(field.value).format('DD/MM/YYYY')
                              : ''
                          }
                          onChange={(date) => {
                            field.onChange(date ? new Date(date) : null);
                          }}
                          format="DD/MM/YYYY"
                          valid={isValid('dateOfBirth')}
                          required={
                            thirdPartyDataConfig.dateOfBirth.isMandatory
                          }
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                )}
                {isIndividual && (
                  <FormControl errorMessage={errors?.nationality?.message}>
                    <FieldSelect
                      label="Nationality"
                      id="nationality"
                      register={register}
                      options={countries}
                      placeholder="Please select a country"
                      placeholderValue="DEFAULT"
                      fullWidth
                      valid={isValid('nationality')}
                      required={thirdPartyDataConfig.nationality.isMandatory}
                    />
                  </FormControl>
                )}
                {isIndividual && (
                  <FormControl errorMessage={errors.passportNumber?.message}>
                    <FieldInput
                      label="Passport Number"
                      id="passportNumber"
                      name="passportNumber"
                      register={register}
                      fullWidth
                      valid={isValid('passportNumber')}
                      required={thirdPartyDataConfig.passportNumber.isMandatory}
                    />
                  </FormControl>
                )}
                {isIndividual && (
                  <FormControl errorMessage={errors.nationalIDNumber?.message}>
                    <FieldInput
                      label="National ID Number"
                      id="nationalIDNumber"
                      name="nationalIDNumber"
                      register={register}
                      fullWidth
                      valid={isValid('nationalIDNumber')}
                      required={
                        thirdPartyDataConfig.nationalIDNumber.isMandatory
                      }
                    />
                  </FormControl>
                )}
                {isIndividual && (
                  <FormControl errorMessage={errors.otherIDNumber?.message}>
                    <FieldInput
                      label="Other ID Number"
                      id="otherIDNumber"
                      name="otherIDNumber"
                      register={register}
                      fullWidth
                      valid={isValid('otherIDNumber')}
                      required={thirdPartyDataConfig.otherIDNumber.isMandatory}
                    />
                  </FormControl>
                )}
                {isBusiness && (
                  <FormControl
                    errorMessage={errors.registrationNumber?.message}
                  >
                    <FieldInput
                      label="Registration"
                      id="registrationNumber"
                      name="registrationNumber"
                      register={register}
                      fullWidth
                      valid={isValid('registrationNumber')}
                      required={
                        thirdPartyDataConfig.registrationNumber.isMandatory
                      }
                    />
                  </FormControl>
                )}
                {isBusiness && (
                  <FormControl errorMessage={errors.swift?.message}>
                    <FieldInput
                      label="SWIFT / BIC"
                      id="swift"
                      name="swift"
                      register={register}
                      fullWidth
                      valid={isValid('swift')}
                      required={thirdPartyDataConfig.swift.isMandatory}
                    />
                  </FormControl>
                )}
                {isBusiness && (
                  <FormControl errorMessage={errors.duns?.message}>
                    <FieldInput
                      label="DUNS"
                      id="duns"
                      name="duns"
                      register={register}
                      fullWidth
                      valid={isValid('duns')}
                      required={thirdPartyDataConfig.duns.isMandatory}
                    />
                  </FormControl>
                )}
                {isBusiness && (
                  <FormControl errorMessage={errors?.companyUrl?.message}>
                    <FieldInput
                      label="Company website"
                      id="companyUrl"
                      name="companyUrl"
                      register={register}
                      fullWidth
                      valid={isValid('companyUrl')}
                      required={thirdPartyDataConfig.companyUrl.isMandatory}
                    />
                  </FormControl>
                )}
                {isBusiness && (
                  <FormControl errorMessage={errors.keyFullName?.message}>
                    <FieldInput
                      label="Key contact name"
                      id="keyFullName"
                      name="keyFullName"
                      register={register}
                      fullWidth
                      valid={isValid('keyFullName')}
                      required={thirdPartyDataConfig.keyFullName.isMandatory}
                    />
                  </FormControl>
                )}
                <FormControl errorMessage={errors.keyPhoneNumber?.message}>
                  <FieldInput
                    label="Key phone number"
                    id="keyPhoneNumber"
                    name="keyPhoneNumber"
                    register={register}
                    fullWidth
                    valid={isValid('keyPhoneNumber')}
                    required={thirdPartyDataConfig.keyPhoneNumber.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.keyEmail?.message}>
                  <FieldInput
                    label="Key contact email"
                    id="keyEmail"
                    name="keyEmail"
                    register={register}
                    fullWidth
                    valid={isValid('keyEmail')}
                    required={thirdPartyDataConfig.keyEmail.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.referenceField1?.message}>
                  <FieldInput
                    label={thirdPartyDataConfig.referenceField1.customName}
                    id="referenceField1"
                    name="referenceField1"
                    register={register}
                    fullWidth
                    valid={isValid('referenceField1')}
                    required={thirdPartyDataConfig.referenceField1.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.referenceField2?.message}>
                  <FieldInput
                    label={thirdPartyDataConfig.referenceField2.customName}
                    id="referenceField2"
                    name="referenceField2"
                    register={register}
                    fullWidth
                    valid={isValid('referenceField2')}
                    required={thirdPartyDataConfig.referenceField2.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.referenceField3?.message}>
                  <FieldInput
                    label={thirdPartyDataConfig.referenceField3.customName}
                    id="referenceField3"
                    name="referenceField3"
                    register={register}
                    fullWidth
                    valid={isValid('referenceField3')}
                    required={thirdPartyDataConfig.referenceField3.isMandatory}
                  />
                </FormControl>
                <FormControl errorMessage={errors.internalNotes?.message}>
                  <FieldTextarea
                    label="Internal notes"
                    id="internalNotes"
                    name="internalNotes"
                    register={register}
                    fullWidth
                    valid={isValid('internalNotes')}
                    required={thirdPartyDataConfig.internalNotes.isMandatory}
                  />
                </FormControl>
              </Column>
            </ColumnsContainer>

            <ButtonWrapper>
              <FixedWidthButton
                secondary
                type="submit"
                disabled={isSubmitLoading}
              >
                Save
              </FixedWidthButton>
              <Button
                text
                type="button"
                disabled={isSubmitLoading}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </ButtonWrapper>
          </EditForm>
        </>
      )}
      <Dialog
        isOpen={showDialog}
        onConfirm={handleConfirmDialog}
        onDecline={handleDeclineDialog}
      >
        <p>
          Any changes will not be applied to any completed or in progress Due
          Diligence reports
        </p>
      </Dialog>
    </Wrapper>
  );
};

export default ThirdPartyEdit;
