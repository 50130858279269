import React from 'react';
import dayjs from 'dayjs';
import { ExpandableParagraph, Panel } from 'components/molecules';
import { Section, Title } from '../../ThirdPartyProfile.styles';
import {
  InternalNotes,
  SummaryPanels,
  GeneralPanels,
  CommentsContainer,
  ItallicNote,
} from './Profile.styles';
import {
  Date,
  DescriptionList,
  Map,
  RiskRating,
  Typography,
} from 'components/atoms';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useAuth } from 'auth';
import { GatewayPermissions } from 'auth/constants/permissions';
import { useCountryHelper } from 'helpers/countriesHelper';

const Profile = ({ id, profile }) => {
  const { hasPermission } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const getOwnerName = (position: number) => {
    const owner = profile?.thirdPartyOwners?.[position]?.users;
    const items = [owner?.firstName, owner?.lastName].filter(Boolean);
    let name = items.length > 0 ? items.join(' ') : '-';
    if (owner?.company) {
      name += ` (${owner.company})`;
    }
    return name;
  };
  const { getName } = useCountryHelper();

  const getAddress = () => {
    const items = [
      profile?.addressLineOne,
      profile?.city,
      profile?.zipCode,
    ].filter(Boolean);
    return items.length > 0 ? items.join(', ') : '-';
  };
  const approvalComment =
    profile?.thirdPartyApproval?.[profile?.thirdPartyApproval?.length - 1]
      ?.comments;
  const getRiskRating = () => (
    <RiskRating
      risk={profile?.thirdPartyApproval?.slice(0).reverse()[0]?.riskRating}
    />
  );
  const getLastReviewDate = () => {
    const lastReviewDate = profile?.thirdPartyApproval?.[0]?.reviewDate;
    return lastReviewDate ? <Date dateTime={lastReviewDate} /> : '-';
  };
  const getNextReviewDate = () => {
    return profile?.nextReviewDate ? (
      <Date dateTime={profile?.nextReviewDate} />
    ) : null;
  };
  const creationMeta = () => {
    const base = [
      {
        key: 'Date created',
        value: dayjs(profile?.createdDate).format('Do MMMM, YYYY'),
      },
      {
        key: 'Created by',
        value: profile?.createdBy,
      },
    ];
    if (profile?.updatedDate) {
      base.push({
        key: 'Date last modified',
        value: dayjs(profile?.updatedDate).format('Do MMMM, YYYY'),
      });
    }
    if (profile?.updatedBy) {
      base.push({ key: 'Modified by', value: profile?.updatedBy });
    }
    return base;
  };
  const editLink = `/third-party/edit/${id}`;
  const hasEditPermission = hasPermission(
    GatewayPermissions.EditThirdPartyRelatedEntity
  );
  return (
    <Section>
      <Title>
        {profile?.primaryCountry && (
          <Map countryCode={profile.primaryCountry} />
        )}
        {(profile?.name || profile?.alternativeName) && (
          <Typography
            tag="h2"
            value={profile?.name || profile?.alternativeName}
          />
        )}
      </Title>
      <SummaryPanels>
        <Panel>
          <DescriptionList
            detailsFlexDirection="column"
            items={[
              {
                key: 'Related entities',
                value: profile?.relatedEntityThirdParty?.length || '0',
              },
            ]}
          />
        </Panel>
        <Panel>
          <DescriptionList
            detailsFlexDirection="column"
            items={[
              {
                key: 'Monitoring',
                value: profile?.monitoring ? 'Enabled' : 'Disabled',
              },
            ]}
          />
        </Panel>
      </SummaryPanels>
      <GeneralPanels>
        <Panel
          cta={
            hasPermission(GatewayPermissions.ApproveRejectThirdParty) &&
            `/third-party/edit-approval/${id}`
          }
          icon="edit-pencil"
        >
          <DescriptionList
            items={[
              {
                key: 'Current approval status',
                value:
                  profile?.thirdPartyApproval?.slice(0).reverse()?.[0]
                    ?.approvalStatus || '-',
              },
              {
                key: 'Risk rating',
                value: getRiskRating(),
              },
              {
                key: 'Last review date',
                value: getLastReviewDate(),
              },
            ]}
            spaced
          />
          <CommentsContainer>
            <Typography className="title" tag="span" value="Comments:" />
            {approvalComment ? (
              <ExpandableParagraph
                text={approvalComment}
                maximumTextLength={283}
              />
            ) : (
              <ItallicNote>No comments provided</ItallicNote>
            )}
          </CommentsContainer>
        </Panel>
        <Panel cta={hasEditPermission && editLink} icon="edit-pencil">
          <DescriptionList
            items={[
              { key: 'Name', value: profile?.name || '-' },
              {
                key: 'Alternative names',
                value: profile?.alternativeName || '-',
              },
              {
                key: `${terminologyConfig?.internalIdSingularNaming}`,
                value: profile?.internalId || '-',
              },
              {
                key: 'Next review date',
                value: getNextReviewDate(),
              },
              {
                key: `${terminologyConfig?.internalOwnerSingularNaming} 1`,
                value: getOwnerName(0),
              },
              {
                key: `${terminologyConfig?.internalOwnerSingularNaming} 2`,
                value: getOwnerName(1),
              },
              {
                key: `${terminologyConfig?.internalOwnerSingularNaming} 3`,
                value: getOwnerName(2),
              },
              { key: 'Address', value: getAddress() },
              {
                key: 'Primary country',
                value: profile?.primaryCountry
                  ? getName(profile?.primaryCountry)
                  : '-',
              },
              {
                key: 'Country 2',
                value: profile?.countryTwo ? getName(profile?.countryTwo) : '-',
              },
              {
                key: 'Country 3',
                value: profile?.countryThree
                  ? getName(profile?.countryThree)
                  : '-',
              },
              ...(profile?.type === 'Individual'
                ? [
                    {
                      key: 'Passport number',
                      value: profile?.passportNumber || '-',
                    },
                    {
                      key: 'National ID number',
                      value: profile?.nationalIDNumber || '-',
                    },
                    {
                      key: 'Other ID number',
                      value: profile?.otherIDNumber || '-',
                    },
                  ]
                : [
                    { key: 'DUNS', value: profile?.duns || '-' },
                    {
                      key: 'Registration number',
                      value: profile?.registrationNumber || '-',
                    },
                    { key: 'SWIFT BIC', value: profile?.swift || '-' },
                    {
                      key: 'Company Website',
                      value: profile?.companyWebsite || '-',
                    },
                  ]),
            ]}
            spaced
            truncateText
          />
        </Panel>
        <Panel cta={hasEditPermission && editLink} icon="edit-pencil">
          <DescriptionList
            items={[
              { key: 'Key contact', value: profile?.keyFullName || '-' },
              {
                key: 'Key contact number',
                value: profile?.keyPhoneNumber || '-',
              },
              { key: 'Key contact email', value: profile?.keyEmail || '-' },
            ]}
            spaced
          />
        </Panel>
        <Panel cta={hasEditPermission && editLink} icon="edit-pencil">
          <DescriptionList
            detailsFlexDirection={'column'}
            items={[
              {
                key: profile?.referenceField1Name,
                value: profile?.referenceField1 || '-',
              },
              {
                key: profile?.referenceField2Name,
                value: profile?.referenceField2 || '-',
              },
              {
                key: profile?.referenceField3Name,
                value: profile?.referenceField3 || '-',
              },
            ]}
            spaced
          />
        </Panel>
        <Panel cta={hasEditPermission && editLink} icon="edit-pencil">
          <InternalNotes>
            <Typography tag="span" value="Internal notes:" />
            <ExpandableParagraph text={profile?.internalNotes} />
          </InternalNotes>
        </Panel>
        <Panel>
          <DescriptionList items={creationMeta()} spaced />
        </Panel>
      </GeneralPanels>
    </Section>
  );
};

export default Profile;
