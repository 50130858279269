import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';

const stateMap = {
  Archived: 'Archived',
  Errored: 'Errored',
  InProgress: 'In progress',
  New: 'In progress',
};

const statusMapping = {
  Incomplete: 'Incomplete',
  CompleteAllMatchesDiscarded: 'Complete - All matches discarded',
  CompletePossibleMatchesFound: 'Complete - Possible matches',
  CompleteMatchesFound: 'Complete - Confirmed matches',
  CompleteNoMatchesFound: 'Complete - No matches found',
  Complete: 'Complete',
};

const hasReportReady = [
  'Updated report ready for review',
  'Report ready for review',
];

const handleUnresolvedState = (state, isSelfService, reportStatus) => {
  if (isSelfService) {
    return state === 'UnresolvedMatches'
      ? 'Incomplete - (Unresolved matches)'
      : 'Incomplete - (Monitoring alert)';
  }

  if (hasReportReady.includes(reportStatus) || !reportStatus) {
    return 'In progress';
  }

  return statusMapping[reportStatus] ?? reportStatus;
};

export const statusMapper = (
  status: string,
  state: string,
  dueDiligenceType: string,
  reportStatus?: string
) => {
  const isSelfService =
    dueDiligenceType === 'Self Service' ||
    dueDiligenceType === GatewaySubscriptionTypeNames.SelfServiceScreening;

  if (state === 'New' && isSelfService) {
    return state;
  }

  if (state in stateMap) {
    return stateMap[state];
  }

  const statusToMap = statusMapping[status] ?? status;

  switch (state) {
    case 'UnresolvedMatches':
    case 'UnresolvedAlerts':
      return handleUnresolvedState(state, isSelfService, reportStatus);

    case 'ResolvedMonitoring':
    case 'ResolvedClosed':
      return hasReportReady.includes(reportStatus) ? reportStatus : statusToMap;

    default:
      return statusToMap;
  }
};
